import React, { useEffect } from 'react';
import GeneralComponentErrorShield from '../../general/GeneralComponentErrorShield';
import { GeneralOverviewPageRenderedComponentProps } from '../../../types/components/componentTypes';
import useFetchData from '../../../hooks/useFetchData';
import MarketsPage from './MarketsPage';
import { useDispatch } from 'react-redux';
import { DatePicker } from '../../../types/redux/pages/PagesStore';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../redux/pages/actions';
import {
  APPEND_NEW_DATE_PICKER_TO_TITLEBAR,
  REMOVE_DATE_PICKER_FROM_TITLEBAR,
} from '../../../types/redux/pages/pageTypes';
import dayjs from 'dayjs';
import useTitleBarDatePicker from '../../../hooks/useTitleBarDatePicker';
import { getAllRelevantWeekdays } from '../../../utilities/dateUtilities';

const Markets: React.FC<GeneralOverviewPageRenderedComponentProps> = () => {
  const selectedDate = useTitleBarDatePicker('marketDate');
  const dispatch = useDispatch();
  const responsef = useFetchData({
    url: `markets/overview/${selectedDate}`,
    keyName: `markets/overview/${selectedDate}`,
    makeFetch: true,
  });

  useEffect(() => {
    const datePickersToAdd: DatePicker[] = [
      {
        displayOnOverviewPage: true,
        titleBarKey: 'marketDate',
        titleBarTitle: 'Market Date:',
        currentValue: dayjs().format('YYYY-MM-DD'),
        availableDates: getAllRelevantWeekdays(new Date(2007, 0, 1)),
      },
    ];
    dispatch(
      addAdditionalTitleBarComponents(
        datePickersToAdd,
        APPEND_NEW_DATE_PICKER_TO_TITLEBAR
      )
    );

    return () => {
      dispatch(
        removeTitleBarComponent('dataDisplay', REMOVE_DATE_PICKER_FROM_TITLEBAR)
      );
    };
  }, []);

  return (
    <GeneralComponentErrorShield
      dataObjects={[responsef]}
      customLoadingMessages={['Loading Market Data...', '...']}
      customErrorMessage={'No Data Found'}
    >
      <MarketsPage responsef={responsef} />
    </GeneralComponentErrorShield>
  );
};

export default Markets;
